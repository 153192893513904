/* ==========================================================================
	Child registration / edit form
   ========================================================================== */

$('select[data-default]').each(function()
{
	if($(this).prop('multiple') == true)
	{
		if($(this).data('default') != '')
			$(this).val( ($(this).data('default') + '').split(',') );
	}
	else
		$(this).find('option[value="' + $(this).data('default') + '"]').prop('selected', true);
});

var autoCompleteSchools = new Bloodhound({
	datumTokenizer: Bloodhound.tokenizers.whitespace,
	queryTokenizer: Bloodhound.tokenizers.whitespace,
	remote: {
		url: '/api/schools/autocomplete?query=%QUERY',
		wildcard: '%QUERY'
	}
});

$('#school').typeahead({
	highlight: true,
	minLength: 3,
},
{
  name: 'auto_complete_schools',
  display: 'name',
  source: autoCompleteSchools
});

$('#school').on('typeahead:select', function(e, value)
{
	$('#school_id').val( value.remote_id );

	showGroupsAndtarifs(value.remote_id);

});

// used for pre-populating form (previous filled-in or redirect after validation error)
$( document ).ready(function(){
	let remote_id = $("#school_id").val();

	if (typeof remote_id !== "undefined" && remote_id > 0){
		showGroupsAndtarifs(remote_id);
	}
});

function showGroupsAndtarifs(remote_id){
    $.ajax({
        dataType: "json",
        url: '/api/schools/groups',
        data: {'remote_id' : remote_id},
        success: function(data){
            let $el = $("#group_id");
            $el.empty();
            $.each(data.groups, function(key,value) {
                $el.append($("<option></option>")
                    .attr("value", value).text(key));
            });

            let old_group_id = $('#old_group_id').val();

            if (typeof old_group_id !== "undefined" && old_group_id > 0) {
                $('#group_id option[value=\'' + old_group_id + '\']')
					.attr("selected", "selected");
            }

            $( "#starting" ).change();

            $( "#tariefStructureel" ).html(data.tariefStructureel);
            $( "#tariefIncidenteel" ).html(data.tariefIncidenteel);

            $( "#incassotekst" ).html(data.incassotekst);


        }
    });
}

$('#starting').change(updateAvailableDays);
$('#group_id').change(function() {
    $( "#starting" ).change();
});

$('#opvang_vanaf').change(updateAvailableDays).trigger('change');

var updateRequest;
function updateAvailableDays(e)
{
		var date = $(this).val();

	if(typeof updateRequest != 'undefined')
		updateRequest.abort();

	$('.new_opvang_days').addClass('loading');

	updateRequest = $.getJSON('/api/schools/availability', {'group_id': $('[name="group_id"]').val(), 'date': date}, function(days)
	{
		$('.new_opvang_days>label').hide();

		for(var i in days)
		{
			$('.new_opvang_days>label.opvang_' + days[i]).show();
		}
	})
	.always(function()
	{
		$('.new_opvang_days').removeClass('loading');
	});
}

if($('[data-toggle="showhide_content"]').length)
{
	$('[data-toggle="showhide_content"]').change(function()
	{
		var block = $(this).attr('name');

		if($(this).is(':checked'))
			$('.' + block).show();
		else
			$('.' + block).hide();

		if($('[data-toggle="showhide_content"]:checked').length)
			$('.submit-row').show();
		else
			$('.submit-row').hide();

	}).trigger('change');
}

if($('[data-toggle="validate-child"]').length)
{
	$('[data-toggle="validate-child"]').validate({
		rules: {
		    'telefoon_prive': {
		        require_from_group: [1, '.telephones']
		    },
		    'telefoon_werk': {
		        require_from_group: [1, '.telephones']
		    },
		    'telefoon_mobiel': {
		        require_from_group: [1, '.telephones']
		    }
		}
	});
}

$('.edit-form button[type="submit"]').on('click', function()
{
	$(this).closest('form').data('submit-name', $(this).attr('name'));
});

$('.edit-form').validate({
	ignore: ":hidden:not(textarea)",
	submitHandler: function(form)
	{
		$(form).trigger('beginSubmit');

		if($(form).find('#fileupload').length)
			$(form).find('#fileupload').trigger('startUpload', [true]);
		else
			$(form).trigger('sendSubmit');
	}
});

$('select[data-toggle="multiple-select"]').each(function()
{
	$(this).multipleSelect($.extend({
		selectAllText: 'Selecteer alles',
		selectAllDelimiter: ['',''],
		allSelected: 'Alles geselecteerd',
		countSelected: '# van % geselecteerd',
		noMatchesFound: 'Geen resultaten gevonden',
		filter: true
	}, $(this).data()));
});

$('select[data-toggle="single-select"]').each(function()
{
	$(this).multipleSelect($.extend({
		noMatchesFound: 'Geen resultaten gevonden',
		single: true,
		filter: true,
		placeholder: $(this).children(':first').text()
	}, $(this).data()));
});

$('.edit-form').on('change', '[data-rel="clone-on-value"]', function()
{
	if($(this).val() != '')
	{
		var currentVal = $(this).val().split('-');
		var newInput = $('#datefield-template input').clone();

		newInput
			.prop('disabled', false)
			.insertAfter($(this));

		addDatePickerTo(newInput);

		var nextDate = new Date(currentVal[2], currentVal[1] - 1, currentVal[0]);
			newInput.datepicker('option', 'defaultDate', nextDate);
	}

	var found = 0;
	$('.edit-form').find('.date-fields [data-rel="clone-on-value"]').each(function()
	{
		if($(this).datepicker('getDate') === null)
		{
			if(found == 0)
				found++;
			else
				$(this).remove();
		}
	});
});

var $lastDate = $('.edit-form .date-fields [data-rel="clone-on-value"]:eq(-2)');
	$newDate = $('.edit-form .date-fields [data-rel="clone-on-value"]:last');

if($lastDate.length)
	$newDate.datepicker('option', 'defaultDate', $lastDate.datepicker('getDate'));

// Recurring events
if($('#recurring').length)
{
	$('#recurring_frequency').change(function()
	{
		$('.recurring-interval-label, .recurring-ondays, .recurring-scheduler, .recurring-daypicker').hide();
		if($('#recurring').is(':checked'))
		{
			switch($(this).val())
			{
				case 'daily':
					$('.recurring-interval-days, .recurring-scheduler').show();
					break;
				case 'weekly':
					$('.recurring-interval-weeks, .recurring-ondays, .recurring-scheduler').show();
					break;
				case 'monthly':
					$('.recurring-interval-months, .recurring-scheduler').show();
					break;
				case 'yearly':
					$('.recurring-interval-years, .recurring-scheduler').show();
					break;
				case 'custom':
					$('.recurring-daypicker').show();
					break;
			}
		}
	});

	$('#recurring').change(function()
	{
		if($(this).is(':checked'))
			$('.form-row-recurring').show();
		else
			$('.form-row-recurring').hide();

		$('#recurring_frequency').trigger('change');
	}).trigger('change');

	$('input[name="recurring_until"]').change(function()
	{
		var $container = $(this).closest('.checkbox');
		$container.find('input').prop('disabled', false);
		$container.siblings().find('input[type!="radio"]').prop('disabled', true);
	});
	$('input[name="recurring_until"]:checked').trigger('change');
}

$('.edit-form')
	.on('beginSubmit', function()
	{
		$(this).find('button[type="submit"]').prop('disabled', true);

		$(this).find('button[type="submit"][name="' + $(this).data('submit-name') + '"]').addClass('loading');
	})
	.on('cancelSubmit', function()
	{
		$(this).find('button[type="submit"]').prop('disabled', false).removeClass('loading');
	})
	.on('sendSubmit', function()
	{
		var name = $(this).data('submit-name');
		if(name != '')
		{
			$('<input>')
				.attr('type', 'hidden')
				.attr('name', name)
				.val($(this).find('[name="' + name + '"]').val())
				.appendTo($(this));
		}

		$(this)[0].submit();
	});

/* ==========================================================================
	Aan en afmelden
   ========================================================================== */

moment.locale('nl');

if( $('[data-toggle="moment-timer"]').length )
{
	$('[data-toggle="moment-timer"]').each(function()
	{
		var $timer = $(this);
		$timer
			.data('to', moment($(this).data('to')));
			// .text( $timer.data('to').fromNow() );

		var interval = setInterval(function()
		{
			var diff = $timer.data('to').diff(moment(), 'seconds');
			var label = [];

			if(diff > 0)
			{
				/*var hours = Math.floor( diff / (60*60) );
				if(hours > 0)
					label.push( hours + ' uur' );

				diff -= hours * (60*60);
				var minutes = Math.floor( diff / (60) );
				if(minutes == 1)
					label.push( minutes + ' minuut' );
				else if(minutes > 1)
					label.push( minutes + ' minuten' );

				diff -= minutes * (60);
				if(diff == 1)
					label.push( diff + 'seconde' );
				else if(diff > 1)
					label.push( diff + ' seconden' );

				$timer.text( label.join(', ') );*/

				var hours = Math.floor( diff / (60*60) );
				label.push( ("0" + hours).slice(-2) );

				diff -= hours * (60*60);
				var minutes = Math.floor( diff / (60) );
				label.push( ("0" + minutes).slice(-2) );

				diff -= minutes * (60);
				label.push( ("0" + diff).slice(-2) );

				$timer.text( label.join(':') );
			}
			else
			{
				clearInterval( $timer.data('interval') );

				$('.submit-button-holder').remove();

				$timer.text('U kunt geen wijzigingen meer doorgeven');
			}
		}, 1000);

		$timer.data('interval', interval);
	});
}

/* ==========================================================================
	Documents
   ========================================================================== */

$('table.documents').dataTable({
	language: {
		"searchPlaceholder": "Zoeken op (deel) documentnaam",
	},
	"ordering": false
});

/* ==========================================================================
   Moderation
   ========================================================================== */

$('.album-media').on('click', 'a.rotate', function(e)
{
	e.preventDefault();

	var $tr = $(this).closest('tr');

	var rotate = parseInt($tr.find(':input[data-rel="rotation"]').val());
	rotate += 90;

	$tr.find('img, canvas').css('transform', 'rotate(' + rotate + 'deg)');
	$tr.find(':input[data-rel="rotation"]').val(rotate);
});

$('.album-media').on('click', 'a.remove', function(e)
{
	e.preventDefault();

	$(this).closest('tr').remove();
});

$('[data-toggle="add-album-media"]').each(function()
{
	var url = $(this).data('url');

	var $select = $(this).find('select');

	$(this).find('button').click(function(e)
	{
		e.preventDefault();

		var $button = $(this);
		$button.addClass('loading').prop('disabled', true);

		$.getJSON(url, { albumId: $select.val() }, function(media)
		{
			$('<input>')
				.attr('type', 'hidden')
				.attr('name', 'remove_album[]')
				.val( $select.val() )
				.appendTo( $select.closest('form') );

			var html = $.templates('#media-item').render(media);

			$(html).appendTo('.album-media tbody');

			$select.find('option:selected').remove();
			$button.removeClass('loading').prop('disabled', false);
		});
	});
});

/* ==========================================================================
   Wysiwyg
   ========================================================================== */

$('.wysiwyg').summernote({
	toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['para', ['ul', 'ol']],
        ['extra', ['link','codeview']],
	],
	lang: 'nl-NL',
	onChange: function(value, $instance)
	{
		$($instance.context).val(value);
	}
});

/* ==========================================================================
	Forgot password
   ========================================================================== */

if($('#forgot_type').length)
{
	$('#forgot_type').change(function()
	{
		$('.form-row.ouder, .form-row.overblijfmedewerker, .form-row.directeur').hide();

		$('.form-row.' + $(this).val()).show();
	})
	.trigger('change');
}

if($('[data-toggle="validate-forgot-password"]').length)
{
	$('[data-toggle="validate-forgot-password"]').validate({
		rules: {
		    'forgot_username': {
		        required: function(element)
		       	{
		       		return $('#forgot_type').val() == 'ouder';
		       	}
		    },
		    'name': {
		        required: function(element)
		       	{
		       		return $('#forgot_type').val() == 'directeur';
		       	}
		    },
		    'school': {
		    	required: true
		    },
		    'email': {
		    	required: true,
		    	email: true
		    }
		},
		errorPlacement: function($error, $el)
		{
			if($el.attr('name') == 'school')
				$error.appendTo( $el.closest('.form-row') );
			else
				$error.insertAfter( $el );
		}
	});
}

$('[data-toggle="show"]').click(function(e)
{
	e.preventDefault();
	
	$( $(this).data('show') ).toggle();
});

/* ==========================================================================
   Items
   ========================================================================== */

if($('nav.filter').length)
{
	$('nav.filter').find('form select').change(function(e)
	{
		$(this).closest('form').trigger('submit');
	});
}

if($('ol.album').length)
{
	var links = {};

	$('ol.album').find('a.loading').each(function()
	{
		var backgroundUrl = /^url\((['"]?)(.*)\1\)$/.exec($(this).children('img').css('background-image'));
		if(backgroundUrl)
		{
			var backgroundUrl = backgroundUrl[2];

			if( ! (backgroundUrl in links))
				links[ backgroundUrl ] = [];

			links[ backgroundUrl ].push( $(this) );
		}
	});

	$.each(links, function(url, elements)
	{
		var tmpImg = new Image();
		tmpImg.onload = function()
		{
			$.each(elements, function(key, $element)
			{
				$element.removeClass('loading');
			});
		}
		tmpImg.src = url;
	});
}